<template>
  <div class="voucher page-container">
    <div class="page-title-container">
      <h1 class="page-title">Cargar comprobante</h1>
    </div>

    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-8">
          <b-card tag="article" no-body class="custom-card">
            <b-card-header
              header-tag="section"
              class="custom-card-header d-flex align-items-center"
            >
              <h3>Carga de archivos</h3>
            </b-card-header>
            <b-card-body class="custom-card-body">
              <ul class="list list-unstyled">
                <li>
                  <div class="form-group">
                    <label for="identifierType">Tipo de comprobante * </label>
                    <FormSelect
                      id="identifierType"
                      v-model="voucherType"
                      :items="voucherTypes"
                      defaultOption="Elegir tipo de comprobante"
                      :showError="
                      $v.voucherType.$error &&
                      !$v.voucherType.required
                    "
                    />
                    <FormError
                      v-if="
                      $v.voucherType.$error &&
                      !$v.voucherType.required
                    "
                      message="Tipo de comprobante es requerido"
                    />
                  </div>
                  <div class="form-group" v-if="voucherType === 'manualInvoice' || voucherType === 'manualReceipt'">
                    <label for="paymentDate">Fecha de comprobante *</label>
                    <FormDatepicker
                      label-id="paymentDate"
                      :show-calendar="showIssueDate"
                      style="border: 0"
                      class="custom-form-control"
                      :max="new Date()"
                      @show="showIssueDate = true"
                      @hidden="showIssueDate = false"
                      v-model="issueDate"
                    />
                    <FormError
                      v-if="
                      $v.issueDate.$error &&
                      !$v.issueDate.required
                    "
                      message="Fecha de comprobante es requerido"
                    />
                  </div>
                  <div class="form-group custom-form"
                       v-if="voucherType === 'manualInvoice' || voucherType === 'manualReceipt'">
                    <label for="voucherCode">Número de comprobante *</label>
                    <input
                      id="voucherCode"
                      type="text"
                      maxlength="11"
                      v-model.trim="voucherCode"
                      autocomplete="off"
                      placeholder="Ingresar número de comprobante"
                      class="custom-form-control"
                      :class="{
                      'custom-form-control-error': $v.voucherCode.$error,
                    }"
                    />
                    <FormError v-if="$v.voucherCode.$error && !$v.voucherCode.required"
                               message="Número de comprobante es requerido"/>
                  </div>
                  <div class="form-group custom-form"
                       v-if="voucherType === 'manualInvoice' || voucherType === 'manualReceipt'">
                    <label for="voucherCode">Monto total *</label>
                    <input
                      id="voucherCode"
                      type="text"
                      maxlength="11"
                      v-model.trim="total"
                      autocomplete="off"
                      placeholder="Ingresar total"
                      class="custom-form-control"
                      :class="{
                      'custom-form-control-error': $v.total.$error,
                    }"
                    />
                    <FormError v-if="$v.total.$error && !$v.total.required"
                               message="Total es requerido"/>
                    <FormError v-if="$v.total.$error && !$v.total.decimal"
                               message="Ingrese un monto válido"/>
                  </div>
                </li>
                <li>
                  ID de pago:
                  <span class="text-secondary">{{ payment.code }}</span>
                </li>
                <li>
                  Monto de pago:
                  <span class="text-secondary" v-decimal:pen="payment.amount"></span>
                </li>
              </ul>
              <div class="custom-form">
                <FileInput :acceptFile="acceptedFiles && acceptedFiles.length === 0 ? ['.none'] : acceptedFiles"
                           id-file="input-files" @onChange="onChange" :multiple="true"/>
                <form-error message="Debe seleccionar al menos un archivo, como máximo 2"
                            v-if="$v.files.$error"></form-error>
                <section class="form-content mt-2">
                  <div
                    class="col-12 custom-form custom-form--supplier"
                    style="padding: 0"
                  >
                    <div class="form-group">
                      <label for="comments">Comentarios</label>
                      <textarea
                        id="comments"
                        type="text"
                        v-model.trim="comments"
                        autocomplete="off"
                        maxlength="500"
                        class="custom-form-control"
                      />
                    </div>
                  </div>

                </section>
                <div class="form-group-buttons text-right">
                  <router-link
                    :to="{ name: 'payments-list' }"
                    class="button button-cancel"
                  >
                    Cancelar
                  </router-link>
                  <button class="button button-primary" type="submit" @click="submit">
                    Procesar
                  </button>
                </div>
                <div class="form-errors mt-3" v-if="errors.length > 0">
                  <Alert text="Por favor, solucione los siguientes problemas o caso contrario genera una nota de crédito para cargar una nueva factura."
                         :icon="require('@/core/assets/images/icon-alert-yellow.svg')" class="warning mb-2"/>
                  <b><span class="error">Errores: {{ errors.length }}</span></b><br>

                  <span v-for="(e, i) in errors" class="error" :key="`${i}-error`">{{ e }}<br></span>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { maxLength, minLength, required, requiredIf, decimal } from "vuelidate/lib/validators";
import {Alert, FormError, FormSelect, FormDatepicker} from "wize-admin";
import {FileInput} from "@/core/components";

import {Constants as LocalConstants} from "@/core/utils";
import { PaymentService } from "@/core/services";
import { mapGetters } from "vuex";

export default {
  name: "UploadVoucherPage",
  props: {
    id: String
  },
  components: {
    Alert,
    FileInput,
    FormError,
    FormSelect,
    FormDatepicker
  },
  computed: {
    ...mapGetters(
      {
        user: 'auth/getUser',
      },
    ),
    acceptedFiles: function () {
      const acceptedFiles = [];
      if (this.voucherType === LocalConstants.VoucherTypes.eInvoice.key ||
        this.voucherType === LocalConstants.VoucherTypes.eReceipt.key ||
        this.voucherType === LocalConstants.VoucherTypes.eReceiptForFees.key) {
        acceptedFiles.push('.pdf');
        acceptedFiles.push('.xml');
      } else if (this.voucherType === LocalConstants.VoucherTypes.manualInvoice.key ||
        this.voucherType === LocalConstants.VoucherTypes.manualReceipt.key) {

        acceptedFiles.push('.pdf');
        acceptedFiles.push('.jpg');
        acceptedFiles.push('.jpeg');
        acceptedFiles.push('.png');
      }
      return acceptedFiles;
    },
  },
  data() {
    return {
      payment: {},
      files: [],
      errors: [],
      comments: null,
      voucherTypes: [],
      voucherType: null,
      showIssueDate: false,
      issueDate: null,
      voucherCode: null,
      total: null,
    };
  },
  validations: {
    files: {
      minLength: minLength(1),
      maxLength: maxLength(2),
      required
    },
    voucherType: {required},
    issueDate: {
      required: requiredIf(function () {
        return this.voucherType === LocalConstants.VoucherTypes.manualInvoice.key ||
          this.voucherType === LocalConstants.VoucherTypes.manualReceipt.key
      })
    },
    voucherCode: {
      required: requiredIf(function () {
        return this.voucherType === LocalConstants.VoucherTypes.manualInvoice.key ||
          this.voucherType === LocalConstants.VoucherTypes.manualReceipt.key
      })
    },
    total: {
      required: requiredIf(function () {
        return this.voucherType === LocalConstants.VoucherTypes.manualInvoice.key ||
          this.voucherType === LocalConstants.VoucherTypes.manualReceipt.key
      }), decimal
    },
  },
  methods: {
    onChange(files) {
      this.$v.files.$reset();
      this.files = files;
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      await this.$store.dispatch("app/loading", true);
      this.errors = [];
      const data = {
        code: this.payment.code,
        files: this.files,
        type: LocalConstants.VoucherTypes[this.voucherType].value,
        total: this.total,
        voucherCode: this.voucherCode,
        issueDate: this.issueDate,
        comments: this.comments
      }
      try {
        const resp = await PaymentService.uploadVoucher(data);
        if (resp.payload.messages && resp.payload.messages.length > 0) {
          this.errors = resp.payload.messages;
          return;
        }
        Alert.success("Documentos cargados correctamente");
        this.$router.push({name: 'payments-list'});

      } catch (e) {
        this.errors = [];
        if (e.errors && e.errors.message)
          this.errors.push(e.errors.message)
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
  },
  async created() {
    try {
      await this.$store.dispatch("app/loading", true);
      const resp = (await PaymentService.getPayment(this.id)).payload;
      const voucherTypes = JSON.parse(resp.supplier.voucherTypes);
      this.voucherTypes = []
      for (let key in voucherTypes) {
        if (voucherTypes[key]) {
          this.voucherTypes.push(LocalConstants.VoucherTypes[key]);
        }
      }
      this.payment = resp;
    } catch (e) {
      console.error(e);
      this.$router.push({name: 'payments-list'});
    } finally {
      await this.$store.dispatch("app/loading", false);
    }
  }
};
</script>

<style lang="stylus" scoped>
@import '../Styles/voucher.styl';
</style>
