<template>
  <div class="page-container supplier-list">
    <div class="page-title-container">
      <h1 class="page-title">Proveedores</h1>
    </div>
    <div class="table-container">
      <div
        class="table-header d-flex flex-column flex-md-row justify-content-between align-items-start"
      >
        <div>
          <div
            class="d-flex align-items-center justify-content-between filter"
            @click="showFilters = !showFilters"
          >
            <FiltersIcon />

            <div>Filtrar proveedores</div>
          </div>
        </div>

        <div class="d-flex flex-wrap align-items-center w-100 ml-2">
          <span
            v-for="(val, name) in filters"
            v-bind:key="name"
            class="d-flex justify-content-between mb-1 ml-2 filter-secondary"
          >
            {{ getFilterName(name, val) }}

            <DeleteFilterIcon @click="deleteFilter(name)" />
          </span>
        </div>

        <filter-menu
          :show="showFilters"
          @hide="showFilters = false"
          :options="filterOptions"
          :selectedOptionFilter="filters"
          @setFilters="setFilters"
        ></filter-menu>

        <!-- Otras opciones -->
        <section class="d-flex align-items-center">
          <ButtonWithTooltip
            text="Descargar"
            tooltip-variant="refresh-tooltip"
            @click="downloadReport"
          >
            <template slot="icon">
              <DownloadTableIcon />
            </template>
          </ButtonWithTooltip>
          <SearchBar
            placeholder="Buscar por n° de documento, nombres y apellidos, celular, correo electrónico, serie de huellero"
            @search="onSearch"
            class="search-bar"
          />

          <b-button
            :to="{ name: 'supplier-create' }"
            variant="none"
            class="button button-primary button-table-action"
            >Agregar
          </b-button>
        </section>
      </div>
      <div class="mb-0">
        <table class="custom-table">
          <thead>
            <tr>
              <th>RUC</th>
              <th>Razón Social</th>
              <th>Nombre de contacto</th>
              <th>Celular</th>
              <th>Correo electrónico</th>
              <th>Banco</th>
              <th>Impulsadores</th>
              <th>Estado</th>
              <th class="pr-md-3">Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="o in list" :key="o.id">
              <td class="cell-center pl-3">{{ o.companyId }}</td>
              <td class="td-name">{{ o.companyName }}</td>
              <td class="td-name">{{ o.contactName }}</td>
              <td class="cell-center">{{ o.mobile }}</td>
              <td class="cell-center">{{ o.email }}</td>
              <td class="cell-center">{{ o.bank ? o.bank.name : null }}</td>
              <td class="cell-center">{{ o.pushersCount }}</td>
              <td class="cell-center pl-md-2">
                <span :class="`badge ${getStatus(o.status).color}`">{{
                  getStatus(o.status).label
                }}</span>
              </td>
              <td class="cell-center d_option pr-md-2 position-relative">
                <DropdownTable>
                  <template slot="dropdown-content">
                    <DropdownItem
                      text="Ver datos"
                      :to="{
                        name: 'supplier-details',
                        params: { supplierId: o.id },
                      }"
                    />
                    <DropdownItem
                      text="Editar"
                      :to="{
                        name: 'supplier-edit',
                        params: { supplierId: o.id },
                      }"
                    />
                    <DropdownItem
                      text="Suspender"
                      @click="changeStatus(o.id, SupplierStatus.INACTIVE.key)"
                      v-if="
                        o.status === SupplierStatus.ACTIVE.key ||
                        o.status === SupplierStatus.LOCKED.key
                      "
                    />
                    <DropdownItem
                      text="Activar"
                      @click="changeStatus(o.id, SupplierStatus.ACTIVE.key)"
                      v-if="o.status === SupplierStatus.INACTIVE.key"
                    />
                    <DropdownItem
                      text="Desbloquear"
                      @click="changeStatus(o.id, SupplierStatus.ACTIVE.key)"
                      v-if="o.status === SupplierStatus.LOCKED.key"
                    />
                    <DropdownItem
                      text="Recuperar contraseña"
                      @click="recoverPassword(o.id)"
                    />
                  </template>
                </DropdownTable>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-footer d-flex justify-content-end align-items-center">
        <pagination
          :total="total"
          :page="page"
          :page-size="pageSize"
          :pages="pages"
          :changePage="changePage"
          :changePageSize="changePageSize"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  Constants,
  Util,
  Alert,
  FilterMenu,
  FiltersIcon,
  DeleteFilterIcon,
  FilterMixin,
  PaginationMixin,
  DropdownTable,
  DropdownItem,
  SearchBar,
  ButtonWithTooltip, DownloadTableIcon
} from "wize-admin";

import { Constants as LocalConstants } from "@/core/utils";
import { BankService, SupplierService } from "@/core/services";
import fileDownload from "js-file-download";

export default {
  components: {
    SearchBar,
    DropdownTable,
    DropdownItem,
    FilterMenu,
    FiltersIcon,
    DeleteFilterIcon,
    ButtonWithTooltip,
    DownloadTableIcon
  },
  data() {
    return {
      list: [],
      SupplierStatus: LocalConstants.SupplierStatus,
      tooltipType: null,
      filterOptions: [
        {
          label: "Estado",
          key: "status",
          type: Constants.TypeFilters.SINGLE,
          options: [
            LocalConstants.SupplierStatus.ACTIVE,
            LocalConstants.SupplierStatus.INACTIVE,
            LocalConstants.SupplierStatus.LOCKED,
          ],
        },
        {
          label: "Banco",
          key: "banks",
          type: Constants.TypeFilters.MULTIPLE,
          options: [],
        },
        {
          label: "Tipo de gestión contable",
          key: "managementType",
          type: Constants.TypeFilters.SINGLE,
          options: [
            LocalConstants.ManagementType.ON_THE_GROUP,
            LocalConstants.ManagementType.INDIVIDUAL,
          ],
        },
      ],
    };
  },
  mixins: [PaginationMixin, FilterMixin],
  methods: {
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);
        const params = {
          page: this.page - 1,
          size: this.pageSize,
          sort: "companyName",
        };
        const data = { search: this.search, ...this.filters };
        const response = await SupplierService.list(data, params);
        this.list = response.payload.content;
        this.total = response.payload.totalElements;
        this.pages = response.payload.totalPages;
      } catch (e) {
        console.error(e);
        Alert.error(e.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    getStatus(key) {
      return Util.searchValue(key, LocalConstants.SupplierStatus);
    },
    changeStatus(supplierId, status) {
      const self = this;
      Alert.dialog(
        "¿Desea cambiar el estado del proveedor?",
        null,
        async function () {
          try {
            await self.$store.dispatch("app/loading", true);
            const resp = (
              await SupplierService.changeStatus(supplierId, status)
            ).payload;

            const obj = self.list.find((o) => {
              return o.id === resp.id;
            });
            if (obj) {
              const index = self.list.indexOf(obj);
              self.$set(self.list, index, resp);
            }
            Alert.success("Estado actualizado correctamente");
          } catch (e) {
            console.error(e);
            Alert.error(e.errors.message);
          } finally {
            await self.$store.dispatch("app/loading", false);
          }
        }
      );
    },
    async recoverPassword(supplierId) {
      try {
        await this.$store.dispatch("app/loading", true);
        await SupplierService.recoverPassword(supplierId);
        Alert.success(
          "Se ha enviado un correo electrónico al proveedor para que pueda restablecer su contraseña"
        );
      } catch (e) {
        console.error(e);
        Alert.error(e.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    async downloadReport() {
      try {
        await this.$store.dispatch("app/loading", true);
        const data = { search: this.search, ...this.filters };
        const response = await SupplierService.download(data);
        fileDownload(response.data, Util.getFileName(response));
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
  },
  async created() {
    await this.loadData(this.page, this.pageSize);
    const banks = (await BankService.list()).payload;
    const bankOption = this.filterOptions.find((o) => o.key === "banks");
    banks.forEach((b) => bankOption.options.push({ key: b.id, label: b.name }));
  },
};
</script>

<style lang="stylus" scoped>
@import '../Styles/supplier-list';
</style>
