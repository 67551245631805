<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">
        {{ editing ? "Editar proveedor" : "Nuevo proveedor" }}
      </h1>
    </div>
    <div class="container px-0">
      <div class="row justify-content-center">
        <form
          class="col-12 custom-form custom-form--supplier"
          @submit.prevent="save"
          novalidate
        >
          <b-card
            tag="article"
            no-body
            style="max-width: 760px"
            class="custom-card"
          >
            <b-card-header
              header-tag="section"
              class="custom-card-header d-flex align-items-center"
            >
              <h3>
                {{ editing ? "Datos del proveedor" : "Datos del nuevo proveedor" }}
              </h3>
            </b-card-header>
            <b-card-body class="custom-card-body card-border">
              <section>
                <h2 class="form-subtitle">Datos personales</h2>
                <p class="form-description">
                  Ingresa los datos personales del proveedor
                </p>
              </section>

              <div class="custom-form-row custom-form-row--col-2">
                <div class="form-group">
                  <label for="companyId">RUC *</label>
                  <input
                    id="companyId"
                    type="text"
                    maxlength="11"
                    v-model.trim="supplier.companyId"
                    autocomplete="off"
                    placeholder="Ingresar número de RUC"
                    class="custom-form-control"
                    :class="{
                      'custom-form-control-error': $v.supplier.companyId.$error || companyIdExists,
                    }"
                  />
                  <FormError v-if="companyIdExists"
                             message="Ya existe un proveedor registrado con el mismo RUC"/>
                  <FormError v-if="$v.supplier.companyId.$error && !$v.supplier.companyId.required"
                             message="RUC es requerido"/>
                  <FormError v-if="$v.supplier.companyId.$error && !$v.supplier.companyId.numeric"
                             message="RUC debe tener un valor numérico"/>
                  <FormError v-if="$v.supplier.companyId.$error && !$v.supplier.companyId.minLength"
                             message="RUC debe tener 11 dígitos"/>
                </div>

                <div class="form-group">
                  <label for="companyName">Razón social *</label>
                  <input
                    id="companyName"
                    type="text"
                    maxlength="200"
                    v-model.trim="supplier.companyName"
                    autocomplete="off"
                    placeholder="Ingresar razón social"
                    class="custom-form-control"
                    :class="{
                      'custom-form-control-error': $v.supplier.companyName.$error,
                    }"
                  />
                  <FormError
                    v-if="
                      $v.supplier.companyName.$error &&
                      !$v.supplier.companyName.required
                    "
                    message="Número de documento es requerido"
                  />
                </div>
              </div>

              <div class="custom-form-row">
                <div class="form-group">
                  <label for="companyAddress">Domicilio fiscal *</label>
                  <input
                    id="companyAddress"
                    type="text"
                    v-model.trim="supplier.companyAddress"
                    autocomplete="off"
                    placeholder="Ingresar domicilio fiscal"
                    class="custom-form-control"
                    :class="{
                      'custom-form-control-error':
                        $v.supplier.companyAddress.$error,
                    }"
                  />
                  <FormError
                    v-if="
                      $v.supplier.companyAddress.$error &&
                      !$v.supplier.companyAddress.required
                    "
                    message="Domicilio fiscal es requerido"
                  />
                </div>
              </div>
              <div class="custom-form-row">
                <div class="form-group">
                  <label for="companyAddress">Nombre de contacto *</label>
                  <input
                    id="contactName"
                    type="text"
                    v-model.trim="supplier.contactName"
                    autocomplete="off"
                    placeholder="Nombre de contacto"
                    class="custom-form-control"
                    :class="{
                      'custom-form-control-error':
                        $v.supplier.contactName.$error,
                    }"
                  />
                  <FormError
                    v-if="
                      $v.supplier.contactName.$error &&
                      !$v.supplier.contactName.required
                    "
                    message="Nombre de contacto es requerido"
                  />
                </div>
              </div>
              <div class="custom-form-row">
                <div class="form-group">
                  <label for="ubigeo">Ubigeo *</label>
                  <FormSelect
                    id="ubigeo"
                    v-model="supplier.ubigeo"
                    :items="districts"
                    defaultOption="Elegir ubigeo"
                    :showError="
                      $v.supplier.ubigeo.$error
                    "
                  />

                  <FormError
                    v-if="
                      $v.supplier.ubigeo.$error &&
                      !$v.supplier.ubigeo.required
                    "
                    message="Ubigeo es requerido"
                  />
                </div>

              </div>
              <div class="custom-form-row custom-form-row--col-2">
                <div class="form-group">
                  <label for="authorizer">Autorizador</label>
                  <input
                    id="authorizer"
                    type="text"
                    v-model.trim="supplier.authorizer"
                    autocomplete="off"
                    placeholder="Ingresar autorizador"
                    class="custom-form-control"
                    :class="{
                      'custom-form-control-error': $v.supplier.authorizer.$error,
                    }"
                  />
                  <FormError
                    v-if="$v.supplier.authorizer.$error && !$v.supplier.authorizer.email"
                    message="Ingresa un correo electrónico válido"
                  />
                </div>

                <div class="form-group">
                  <label for="payer">Pagador</label>
                  <input
                    id="payer"
                    type="text"
                    v-model.trim="supplier.payer"
                    autocomplete="off"
                    placeholder="Ingresar pagador"
                    class="custom-form-control"
                    :class="{
                      'custom-form-control-error': $v.supplier.payer.$error,
                    }"
                  />
                  <FormError
                    v-if="$v.supplier.payer.$error && !$v.supplier.payer.email"
                    message="Ingresa un correo electrónico válido"
                  />
                </div>
              </div>
              <div class="custom-form-row custom-form-row--col-2">
                <div class="form-group">
                  <label for="phone">Teléfono</label>
                  <input
                    id="phone"
                    type="text"
                    maxlength="9"
                    v-model.trim="supplier.phone"
                    autocomplete="off"
                    placeholder="Ingresar teléfono"
                    class="custom-form-control"
                    :class="{
                      'custom-form-control-error': $v.supplier.phone.$error,
                    }"
                  />
                  <FormError
                    v-if="$v.supplier.phone.$error && !$v.supplier.phone.numeric"
                    message="Telefóno debe ser un valor numérico"
                  />
                </div>

                <div class="form-group">
                  <label for="mobile">Celular</label>
                  <input
                    id="mobile"
                    type="text"
                    v-model.trim="supplier.mobile"
                    autocomplete="off"
                    placeholder="Ingresar celular"
                    maxlength="9"
                    class="custom-form-control"
                    :class="{
                      'custom-form-control-error': $v.supplier.mobile.$error,
                    }"
                  />
                  <FormError
                    v-if="$v.supplier.mobile.$error && !$v.supplier.mobile.numeric"
                    message="Celular debe ser un valor numérico"
                  />
                  <FormError
                    message="Ingresa un celular válido de 9 dígitos"
                    v-if="$v.supplier.mobile.$error && !$v.supplier.mobile.mobile"
                  ></FormError>
                </div>
              </div>

              <div class="custom-form-row custom-form-row--col-2">
                <div class="form-group">
                  <label for="email">Correo electrónico</label>
                  <input
                    id="email"
                    type="text"
                    v-model.trim="supplier.email"
                    autocomplete="off"
                    placeholder="Ingresar correo electrónico"
                    class="custom-form-control"
                    :class="{
                      'custom-form-control-error': $v.supplier.email.$error,
                    }"
                  />
                  <FormError
                    v-if="$v.supplier.email.$error && !$v.supplier.email.email"
                    message="Ingresa un correo electrónico válido"
                  />
                </div>
              </div>

              <div class="custom-form-row custom-form-row--col-2">
                <div class="form-group">
                  <label for="type">Tipo de proveedor *</label>
                  <FormSelect
                    id="type"
                    :items="types"
                    v-model="supplier.type"
                    defaultOption="Elegir tipo de proveedor"
                    :showError="
                      $v.supplier.type.$error
                    "
                  />

                  <FormError
                    v-if="
                      $v.supplier.type.$error &&
                      !$v.supplier.type.required
                    "
                    message="Tipo es requerido"
                  />
                </div>
                <div class="form-group">
                  <label for="managementType">Tipo de gestión contable *</label>
                  <FormSelect
                    id="managementType"
                    :items="managementTypes"
                    v-model="supplier.managementType"
                    defaultOption="Elegir tipo de gestión contable"
                    :showError="
                      $v.supplier.managementType.$error
                    "
                  />

                  <FormError
                    v-if="
                      $v.supplier.managementType.$error &&
                      !$v.supplier.managementType.required
                    "
                    message="Tipo de gestión contable es requerido"
                  />
                </div>
              </div>

              <div class="custom-form-row custom-form-row--col-2">
                <div class="form-group">
                  <label for="expense-line">Línea de gasto</label>
                  <FormSelect
                    id="expense-line"
                    v-model="supplier.expenseLineId"
                    :items="expenseLines"
                    defaultOption="Elegir línea de gasto"
                  />
                </div>
              </div>
            </b-card-body>
            <b-card-body class="custom-card-body pt card-border">
              <section>
                <h2 class="form-subtitle">Comprobantes</h2>
                <p class="form-description">
                  Selecciona los comprobantes admitidos para el proveedor.
                </p>
              </section>
              <input type="checkbox" id="eInvoice" name="eInvoice" value="eInvoice" v-model="supplier.eInvoice">
              <label for="eInvoice" class="ml-2">Factura electrónica</label><br>
              <input type="checkbox" id="eReceipt" name="eReceipt" value="eReceipt" v-model="supplier.eReceipt">
              <label for="eReceipt" class="ml-2">Boleta electrónica</label><br>
              <input type="checkbox" id="eReceiptForFees" name="ereceiptForFees" value="eReceiptForFees" v-model="supplier.eReceiptForFees">
              <label for="eReceiptForFees" class="ml-2">Recibo por honorarios electrónicos</label><br>
              <input type="checkbox" id="manualInvoice" name="eInvoice" value="manualInvoice" v-model="supplier.manualInvoice">
              <label for="manualInvoice" class="ml-2">Factura manual</label><br>
              <input type="checkbox" id="manualReceipt" name="manualReceipt" value="manualReceipt" v-model="supplier.manualReceipt">
              <label for="manualReceipt" class="ml-2">Boleta manual</label><br>
              <FormError
                v-if="showVoucherTypeError"
                message="Debe seleccionar al menos un tipo de comprobante"
              />
            </b-card-body>
            <b-card-body class="custom-card-body pt card-border">
              <section>
                <h2 class="form-subtitle">Datos bancarios</h2>
                <p class="form-description">
                  Ingresa los datos de la cuenta bancaria donde el proveedor
                  recibirá sus abonos
                </p>
              </section>

              <div class="custom-form-row custom-form-row--col-2">
                <div class="form-group">
                  <label for="bankHolder">Nombre del titular</label>
                  <input
                    id="bankHolder"
                    maxlength="200"
                    disabled
                    type="text"
                    v-model.trim="supplier.companyName"
                    autocomplete="off"
                    placeholder="Ingresar nombre del titular"
                    class="custom-form-control"
                  />
                </div>

                <div class="form-group">
                  <label for="bank">Banco</label>
                  <FormSelect
                    id="bank"
                    v-model="supplier.bankId"
                    :items="banks"
                    defaultOption="Elegir banco"
                  />
                </div>
              </div>

              <div class="custom-form-row custom-form-row--col-2">
                <div class="form-group">
                  <label for="bankAccount">Número de cuenta bancaria</label>
                  <input
                    id="bankAccount"
                    type="text"
                    maxlength="20"
                    v-model.trim="supplier.bankAccount"
                    autocomplete="off"
                    placeholder="Ingresar número de cuenta bancaria"
                    class="custom-form-control"
                    :class="{
                      'custom-form-control-error': $v.supplier.bankAccount.$error,
                    }"
                  />
                  <FormError
                    v-if="
                      $v.supplier.bankAccount.$error &&
                      !$v.supplier.bankAccount.numeric
                    "
                    message="Número de cuenta bancaria debe tener un valor numérico"
                  />
                  <FormError
                    v-if="
                      $v.supplier.bankAccount.$error &&
                      !$v.supplier.bankAccount.required
                    "
                    message="Número de cuenta bancaria es requerido"
                  />
                </div>

                <div class="form-group">
                  <label for="accountType">Tipo de cuenta bancaria</label>
                  <FormSelect
                    id="accountType"
                    v-model="supplier.accountType"
                    :items="accountTypes"
                    defaultOption="Elegir tipo de cuenta bancaria"
                  />
                </div>
              </div>
              <div class="custom-form-row custom-form-row--col-2">
                <div class="form-group mb-0">
                  <label for="numberCci">Número de CCI</label>
                  <input
                    id="numberCci"
                    type="text"
                    maxlength="20"
                    v-model.trim="supplier.bankInterAccount"
                    autocomplete="off"
                    placeholder="Ingresar número de CCI"
                    class="custom-form-control"
                    :class="{
                      'custom-form-control-error':
                        $v.supplier.bankInterAccount.$error,
                    }"
                  />
                  <FormError
                    v-if="
                    $v.supplier.bankInterAccount.$error &&
                    !$v.supplier.bankInterAccount.numeric
                  "
                    message="Número de CCI debe tener un valor numérico"
                  />
                  <FormError v-if="$v.supplier.bankInterAccount.$error && !$v.supplier.bankInterAccount.minLength"
                             message="Número de CCI debe tener 20 dígitos"/>
                  <FormError
                    v-if="
                      $v.supplier.bankInterAccount.$error &&
                      !$v.supplier.bankInterAccount.required
                    "
                    message="Número de CCI es requerido"
                  />
                </div>
                <div class="form-group mb-0">
                  <label for="detractionAccount">Cuenta de detracciones *</label>
                  <input
                    id="detractionAccount"
                    type="text"
                    v-model.trim="supplier.detractionAccount"
                    autocomplete="off"
                    maxlength="20"
                    placeholder="Ingresar cuenta de detracciones"
                    class="custom-form-control"
                    :class="{
                      'custom-form-control-error':
                        $v.supplier.detractionAccount.$error,
                    }"
                  />
                  <FormError
                    v-if="
                      $v.supplier.detractionAccount.$error &&
                      !$v.supplier.detractionAccount.numeric
                    "
                    message="Cuenta de detracciones debe tener un valor numérico"
                  />
                  <FormError
                    v-if="
                      $v.supplier.detractionAccount.$error &&
                      !$v.supplier.detractionAccount.required
                    "
                    message="Cuenta de detracciones es requerida"
                  />
                </div>
              </div>
              <div class="form-group-buttons text-right">
                <router-link
                  :to="{ name: 'supplier-list' }"
                  class="button button-light"
                >
                  {{ editing ? "Volver" : "Cancelar" }}
                </router-link>
                <button class="button button-primary" type="submit">
                  Guardar
                </button>
              </div>
            </b-card-body>
          </b-card>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { Alert, CustomValidators, FormError, FormSelect } from 'wize-admin'

import { BankService, ExpenseLineService, SupplierService, UbigeoService } from "@/core/services";
import { Constants as LocalConstants } from "@/core/utils";
import { email, minLength, numeric, required, requiredIf } from "vuelidate/lib/validators";

export default {
  components: {
    FormError,
    FormSelect,
  },
  data() {
    return {
      supplier: {
        companyId: null,
        companyName: null,
        companyAddress: null,
        contactName: null,
        phone: null,
        mobile: null,
        email: null,
        detractionAccount: null,
        managementType: null,
        type: null,
        bankId: null,
        bankAccount: null,
        bankInterAccount: null,
        authorizer: null,
        payer: null,
        ubigeo: null,
        expenseLineId: null,
        eInvoice: false,
        eReceipt: false,
        eReceiptForFees: false,
        manualInvoice: false,
        manualReceipt: false,
        accountType: null,
      },
      editing: false,
      companyIdExists: false,
      banks: [],
      districts: [],
      expenseLines: [],
      accountTypes: [
        LocalConstants.AccountType.SAVINGS,
        LocalConstants.AccountType.CURRENT,
        LocalConstants.AccountType.SALARY,
      ],
      managementTypes: [
        LocalConstants.ManagementType.ON_THE_GROUP,
        LocalConstants.ManagementType.INDIVIDUAL,
      ],
      types: [
        LocalConstants.SupplierType.REGULAR,
        LocalConstants.SupplierType.SPECIAL,
      ],
      showVoucherTypeError: false
    };
  },
  validations: {
    supplier: {
      companyId: {required, numeric, minLength: minLength(11)},
      companyName: {required},
      companyAddress: {required},
      contactName: {required},
      phone: {numeric},
      mobile: {numeric, mobile: CustomValidators.mobile},
      email: {email},
      detractionAccount: {numeric, required},
      bankAccount: {
        numeric, required: requiredIf(function () {
          return this.supplier.bankId;
        })
      },
      bankInterAccount: {
        numeric, minLength: minLength(20),
        required: requiredIf(function () {
          return this.supplier.bankId;
        })
      },
      type: {required},
      managementType: {required},
      authorizer: {email},
      payer: {email},
      ubigeo: {required},
    },
  },
  watch: {
    "$route.params.supplierId": function () {
      if (this.$route.params.supplierId) this.load(this.$route.params.supplierId);
    },
    "supplier.bankId": function () {
      if (!this.supplier.bankId) {
        this.supplier.bankAccount = null;
        this.supplier.bankInterAccount = null;
      }
    },
    "supplier.companyId": async function (val) {
      if (this.editing)
        return;

      this.$v.supplier.companyId.$touch();
      if (this.$v.supplier.companyId.$error) return;
      try {
        this.$store.dispatch("app/loading", true);
        await SupplierService.getByCompanyId(val);
        this.companyIdExists = true;
      } catch (ex) {
        this.companyIdExists = false;
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
  },
  methods: {
    allowCheckout() {
      return this.supplier.eInvoice || this.supplier.eReceipt || this.supplier.eReceiptForFees || this.supplier.manualInvoice || this.supplier.manualReceipt;
    },
    async save() {
      this.showVoucherTypeError = false;
      this.$v.$touch();
      if (this.$v.$error || this.companyIdExists) return;
      try {
        this.$store.dispatch("app/loading", true);
        if(!this.allowCheckout()) {
          this.showVoucherTypeError = true;
          return;
        }
        const data = {...this.supplier};
        data.voucherTypes = JSON.stringify({
          eInvoice: data.eInvoice,
          eReceipt: data.eReceipt,
          eReceiptForFees: data.eReceiptForFees,
          manualInvoice: data.manualInvoice,
          manualReceipt: data.manualReceipt,
        });
        let response;
        if (!this.editing) response = await SupplierService.save(data);
        else
          response = await SupplierService.update(
            this.$route.params.supplierId,
            data
          );
        Alert.success("Se almacenó la información correctamente");
        if (!this.editing) {
          this.$router.push({
            name: "supplier-edit",
            params: {supplierId: response.payload.id},
          });
          this.editing = true;
        }
      } catch (ex) {
        console.error(ex);
        Alert.error(ex.errors.message);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    async load(supplierId) {
      try {
        this.$store.dispatch("app/loading", true);
        const response = await SupplierService.get(supplierId);
        const payload = response.payload;
        this.supplier.companyId = payload.companyId;
        this.supplier.companyName = payload.companyName;
        this.supplier.companyAddress = payload.companyAddress;
        this.supplier.contactName = payload.contactName;
        this.supplier.phone = payload.phone;
        this.supplier.mobile = payload.mobile;
        this.supplier.email = payload.email;
        this.supplier.detractionAccount = payload.detractionAccount;
        this.supplier.managementType = payload.managementType;
        this.supplier.type = payload.type;
        this.supplier.bankAccount = payload.bankAccount;
        this.supplier.bankInterAccount = payload.bankInterAccount;
        this.supplier.bankId = payload.bank?.id;
        this.supplier.ubigeo = payload.ubigeo;
        this.supplier.authorizer = payload.authorizer;
        this.supplier.payer = payload.payer;
        this.supplier.expenseLineId = payload.expenseLine?.id;
        this.supplier.accountType = payload.accountType;
        if (payload.voucherTypes) {
          const voucherTypes = JSON.parse(payload.voucherTypes);
          this.supplier.eInvoice = voucherTypes.eInvoice;
          this.supplier.eReceipt = voucherTypes.eReceipt;
          this.supplier.eReceiptForFees = voucherTypes.eReceiptForFees;
          this.supplier.manualInvoice = voucherTypes.manualInvoice;
          this.supplier.manualReceipt = voucherTypes.manualReceipt;
        }
        this.editing = true;
      } catch (e) {
        console.error(e);
        Alert.error(e.errors.message);
        this.$router.push({name: "supplier-list"});
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
  },
  async created() {
    const banks = (await BankService.list()).payload;
    banks.forEach((b) => this.banks.push({key: b.id, label: b.name}));
    if (this.$route.params.supplierId) this.load(this.$route.params.supplierId);
    const districts = (await UbigeoService.listDistricts()).payload;
    districts.forEach((b) => this.districts.push({key: b.id, label: b.description}));

    const expenseLines = (await ExpenseLineService.listActive()).payload;
    expenseLines.forEach((b) => this.expenseLines.push({key: b.id, label: b.description}));
  },
};
</script>

<style lang="stylus" scoped></style>
