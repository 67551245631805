<template>
  <b-modal
    v-model="showLocal"
    @hidden="hide"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    hide-header
    id="rejection-modal"
  >
    <div class="row">
      <div class="col-md-12">
        <section>
          <div
            class="
              modal-header
              d-flex
              justify-content-between
              align-items-center
            "
          >
            <div class="modal-title modal-title--rejection-modal text-white">Marcar como pagado</div>
            <b-button
              class="icon-close icon-close-primary"
              @click="hideModal"
              aria-label="Close"
            >
            </b-button>
          </div>
        </section>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 text-center">
        <section class="modal-main-content">
          <info-badge
            text="Para actualizar el pago, por favor ingrese la siguiente información"
          >
            <template slot="icon">
              <WarningAlertIcon class="mr-2 alert-icon"/>
            </template>
          </info-badge>

          <form action="" @submit.prevent="confirm" class="custom-form" novalidate>
            <div
              class="
                form-group
                d-flex
                flex-column flex-md-row
                align-items-md-center
                mt-4
                mb-0
              "
            >
              <label for="payedAmount">Monto del abono *</label>
              <input
                id="payedAmount"
                type="text"
                v-model.trim="payedAmount"
                autocomplete="off"
                maxlength="11"
                class="custom-form-control"
                :class="{
                        'custom-form-control-error': $v.payedAmount.$error,
                      }"
              />

            </div>
            <FormError
              v-if="
                        $v.payedAmount.$error &&
                        !$v.payedAmount.required
                      "
              message="Monto del abono es requerido"
            />
            <FormError
              v-if="
                        $v.payedAmount.$error &&
                        !$v.payedAmount.numeric
                      "
              message="Debe ser un valor numérico, no debe incluir comas"
            />
            <div
              class="
                form-group
                d-flex
                flex-column flex-md-row
                align-items-md-center
                mt-4
                mb-0
              "
            >
              <label for="paymentDate">Fecha de abono *</label>
              <FormDatepicker
                label-id="paymentDate"
                :show-calendar="showPaymentDate"
                style="border: 0"
                class="custom-form-control"
                :max="new Date()"
                @show="showPaymentDate = true"
                @hidden="showPaymentDate = false"
                v-model="paymentDate"
              />

            </div>
            <FormError
              v-if="
                        $v.paymentDate.$error &&
                        !$v.paymentDate.required
                      "
              message="Fecha de abono es requerida"
            />
            <div class="form-group-buttons text-right">
              <button
                class="button button-light"
                type="button"
                @click.prevent="hideModal()"
              >
                Cancelar
              </button>
              <button class="button button-primary" type="submit">
                Confirmar
              </button>
            </div>
          </form>
        </section>
      </div>
    </div>
  </b-modal>
</template>
<script>

import {FormDatepicker, InfoBadge, ModalMixin, WarningAlertIcon, FormError} from "wize-admin";
import {required, decimal} from "vuelidate/lib/validators";
import {Constants as LocalConstants} from "@/core/utils";

export default {
  name: "PayedModal",
  props: {
    paymentId: Number
  },
  data() {
    return {
      showPaymentDate: false,
      paymentDate: null,
      payedAmount: null,
    };
  },
  mixins: [ModalMixin],
  validations: {
    payedAmount: {required, decimal},
    paymentDate: {required}
  },
  components: {
    FormDatepicker,
    InfoBadge,
    WarningAlertIcon,
    FormError
  },
  methods: {
    confirm() {
      this.$v.$touch();
      if (this.$v.$error) return;
      this.$emit("confirm", this.paymentId, "¿Desea marcar el pago como pagado?", {
        status: LocalConstants.PaymentStatus.PAID.key,
        paymentDate: this.paymentDate,
        payedAmount: this.payedAmount
      });
      this.hideModal();
    },
    hideModal() {
      this.paymentDate = null;
      this.payedAmount = null;
      this.$emit("hide");
    },
  },
};
</script>

<style lang="stylus">
</style>
