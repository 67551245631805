<template>
  <b-modal
    v-model="showLocal"
    @hidden="hide"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    hide-header
    id="rejection-modal"
  >
    <div class="row">
      <div class="col-md-12">
        <section>
          <div
            class="
              modal-header
              d-flex
              justify-content-between
              align-items-center
            "
          >
            <div class="modal-title modal-title--rejection-modal text-white">Aceptar comprobante</div>
            <b-button
              class="icon-close icon-close-primary"
              @click="hideModal"
              aria-label="Close"
            >
            </b-button>
          </div>
        </section>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 text-center">
        <section class="modal-main-content">
          <info-badge
            text="Por favor indique si el cliente tiene una retención"
          >
            <template slot="icon">
              <WarningAlertIcon class="mr-2 alert-icon"/>
            </template>
          </info-badge>

          <form action="" @submit.prevent="confirm" class="custom-form" novalidate>
            <div
              class="
                form-group
                d-flex
                flex-column flex-md-row
                align-items-md-center
                mt-4
                mb-0
              "
            >
              <label for="hasRetention" class="pt-2 mr-3 checkbox-label">El proveedor tiene retención</label>
              <b-form-checkbox
                id="hasRetention"
                v-model="hasRetention"
                name="check-button"
                switch
                size="lg"
              >
              </b-form-checkbox>
            </div>
            <div
              v-if="hasRetention"
              class="
                form-group
                d-flex
                flex-column flex-md-row
                align-items-md-center
                mt-4
                mb-0
              "
            >
              <label for="prefix">Monto de retención *</label>
              <input
                id="prefix"
                type="text"
                v-model.trim="retentionAmount"
                autocomplete="off"
                maxlength="11"
                class="custom-form-control"
                :class="{
                        'custom-form-control-error': $v.retentionAmount.$error,
                      }"
              />
            </div>
            <div class="error-container text-left">
              <FormError
                v-if="$v.retentionAmount.$error && !$v.retentionAmount.required"
                message="Monto de retención es requerido"
              />
              <FormError
                v-if="$v.retentionAmount.$error && !$v.retentionAmount.numeric"
                message="El monto de retención debe ser un valor numérico"
              />
            </div>
            <div class="form-group-buttons text-right">
              <button
                class="button button-light"
                type="button"
                @click.prevent="hideModal()"
              >
                Cancelar
              </button>
              <button class="button button-primary" type="submit">
                Guardar
              </button>
            </div>
          </form>
        </section>
      </div>
    </div>
  </b-modal>
</template>
<script>

import {FormError, InfoBadge, ModalMixin, WarningAlertIcon} from "wize-admin";
import {numeric, requiredIf} from "vuelidate/lib/validators";
import {Constants as LocalConstants} from "@/core/utils";

export default {
  name: "ApprovalModal",
  props: {
    paymentId: Number
  },
  data() {
    return {
      hasRetention: false,
      retentionAmount: null,
    };
  },
  mixins: [ModalMixin],
  validations: {
    retentionAmount: {
      numeric, required: requiredIf(function () {
        return this.hasRetention;
      })
    }
  },
  components: {
    InfoBadge,
    FormError,
    WarningAlertIcon
  },
  methods: {
    confirm() {
      this.$v.$touch();
      if (this.$v.$error) return;
      this.$emit("confirm", this.paymentId, "¿Desea aceptar el documento?", {
        status: LocalConstants.PaymentStatus.APPROVED.key,
        hasRetention: this.hasRetention,
        retentionAmount: this.retentionAmount
      });
      this.hideModal();
    },
    hideModal() {
      this.$emit("hide");
    },
  },
};
</script>

<style lang="stylus">
</style>
