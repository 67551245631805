import {http} from '@/http'

function getFormData(data) {
  const formData = new FormData();
  for (const key in data) {
    if (Array.isArray(data[key]) && data[key].length > 0) {
      for (const index in data[key]) {
        const dataKeyIndex = data[key][index];

        if (typeof dataKeyIndex === 'string' || typeof dataKeyIndex === 'number') {
          formData.append(`${key}[${index}]`, data[key][index]);
        } else if (dataKeyIndex instanceof File) {
          formData.append(`${key}`, data[key][index]);
        } else {
          for (const key2 in data[key][index]) {
            formData.append(`${key}[${index}].${key2}`, data[key][index][key2] ? data[key][index][key2] : '');
          }
        }
      }
    } else {
      formData.append(key, data[key] ? data[key] : data[key] == null ? '' : data[key]);
    }
  }
  return formData;
}


export default {
  list(data, params) {
    return http.post('/payments/list', data, {params})
  },
  download(data) {
    return http.post('/payments/xlsx', data,  {responseType: 'blob'})
  },
  downloadMacroBank(data) {
    return http.post('/payments/macrobank/xlsx', data,  {responseType: 'blob'})
  },
  getPayment(id) {
    return http.get(`/payments/${id}`)
  },
  listVouchers(data, params) {
    return http.post('/payments/vouchers/list',data, {params})
  },
  downloadVouchers(data) {
    return http.post('/payments/vouchers/xlsx', data, {responseType: 'blob'})
  },
  changeStatus(paymentId, data){
    return http.put(`/payments/${paymentId}/status`, data)
  },
  uploadVoucher(data) {
    const formData = getFormData(data);
    return http.post('/payments/vouchers/upload', formData, {headers: { "Content-Type": "multipart/form-data" },})
  },
}
