<template>
  <b-modal
    v-model="showLocal"
    @hidden="hide"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    hide-header
    id="rejection-modal"
  >
    <div class="row">
      <div class="col-md-12">
        <section>
          <div
            class="
              modal-header
              d-flex
              justify-content-between
              align-items-center
            "
          >
            <div class="modal-title modal-title--rejection-modal text-white">Autorizar</div>
            <b-button
              class="icon-close icon-close-primary"
              @click="hideModal"
              aria-label="Close"
            >
            </b-button>
          </div>
        </section>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 text-center">
        <section class="modal-main-content">
          <info-badge
            text="Para autorizar el pago, por favor ingrese la siguiente información"
          >
            <template slot="icon">
              <WarningAlertIcon class="mr-2 alert-icon"/>
            </template>
          </info-badge>

          <form action="" @submit.prevent="confirm" class="custom-form" novalidate>
            <div
              class="
                form-group
                d-flex
                flex-column flex-md-row
                align-items-md-center
                mt-4
                mb-0
              "
            >
              <label for="projectType" class="pt-2">Proyecto *</label>
              <FormSelect
                id="projectType"
                v-model="projectType"
                :items="projectTypes"
                defaultOption="Elegir proyecto"
                :showError="
                  $v.projectType.$error && !$v.projectType.required
                "
              />
            </div>
            <div
              class="
                form-group
                d-flex
                flex-column flex-md-row
                align-items-md-center
                mt-4
                mb-0
              "
            >
              <label for="prefix">Periodo *</label>
              <input
                id="prefix"
                type="text"
                v-model.trim="period"
                autocomplete="off"
                maxlength="11"
                placeholder="202409-001"
                class="custom-form-control"
                :class="{
                        'custom-form-control-error': $v.period.$error,
                      }"
              />
            </div>

            <div class="form-group-buttons text-right">
              <button
                class="button button-light"
                type="button"
                @click.prevent="hideModal()"
              >
                Cancelar
              </button>
              <button class="button button-primary" type="submit">
                Autorizar
              </button>
            </div>
          </form>
        </section>
      </div>
    </div>
  </b-modal>
</template>
<script>

import {FormSelect, InfoBadge, ModalMixin, WarningAlertIcon} from "wize-admin";
import {required} from "vuelidate/lib/validators";
import {Constants as LocalConstants} from "@/core/utils";

export default {
  name: "AuthorizeModal",
  props: {
    projectTypes: Array,
    paymentId: Number
  },
  data() {
    return {
      projectType: null,
      period: null
    };
  },
  mixins: [ModalMixin],
  validations: {
    projectType: {required},
    period: {required}
  },
  components: {
    InfoBadge,
    FormSelect,
    WarningAlertIcon
  },
  methods: {
    confirm() {
      this.$v.$touch();
      if (this.$v.$error) return;
      const projectType = this.projectTypes.find(o => o.key === this.projectType);
      this.$emit("confirm", this.paymentId, "¿Desea autorizar el estado del pago?", {
        status: LocalConstants.PaymentStatus.AUTHORIZED.key,
        projectTypeId: projectType.key,
        period: this.period
      });
      this.hideModal();
    },
    hideModal() {
      this.projectType = null;
      this.period = null;
      this.$emit("hide");
    },
  },
};
</script>

<style lang="stylus">
</style>
