<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">Datos del proveedor</h1>
    </div>

    <div class="px-0">
      <div class="row justify-content-center">
        <div class="col">
          <b-card tag="article" class="data-card" no-body>
            <section class="section">
              <h2 class="subtitle">Datos personales</h2>

              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-5">
                    <h3 class="label">RUC</h3>
                    <p class="text">
                      {{ supplier.companyId }}
                    </p>
                  </div>

                  <div class="col-md-6 mt-4 mt-md-0">
                    <h3 class="label">Razón Social</h3>
                    <p class="text">
                      {{ supplier.companyName }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <h3 class="label">Domicilio fiscal</h3>
                    <p class="text">
                      {{ supplier.companyAddress }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <h3 class="label">Ubigeo</h3>
                    <p class="text">
                      {{ supplier.ubigeoDescription }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-5">
                    <h3 class="label">Autorizador</h3>
                    <p class="text" v-empty="supplier.authorizer">
                    </p>
                  </div>

                  <div class="col-md-6 mt-4 mt-md-0">
                    <h3 class="label">Pagador</h3>
                    <p class="text" v-empty="supplier.payer">
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-5">
                    <h3 class="label">Teléfono</h3>
                    <p class="text" v-empty="supplier.phone">
                    </p>
                  </div>

                  <div class="col-md-6 mt-4 mt-md-0">
                    <h3 class="label">Celular</h3>
                    <p class="text" v-empty="supplier.mobile">
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-5">
                    <h3 class="label">Correo electrónico</h3>
                    <p class="text" v-empty="supplier.email">
                    </p>
                  </div>

                  <div class="col-md-6 mt-4 mt-md-0">
                    <h3 class="label">Cuenta de detracciones</h3>
                    <p class="text" v-empty="supplier.detractionAccount">
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-5">
                    <h3 class="label">Tipo de proveedor</h3>
                    <p class="text">
                      {{ getType(supplier.type).label }}
                    </p>
                  </div>

                  <div class="col-md-6 mt-4 mt-md-0">
                    <h3 class="label">Tipo de gestión contable</h3>
                    <p class="text">
                      {{ getManagementType(supplier.managementType).label }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <h3 class="label">Línea de gasto</h3>
                    <p class="text">
                      {{ supplier.expenseLine ? supplier.expenseLine.description : null }}
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section class="section">
              <h2 class="subtitle">Datos bancarios</h2>

              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-5">
                    <h3 class="label">Titular de cuenta</h3>
                    <p class="text" v-empty="supplier.bankHolder">
                    </p>
                  </div>

                  <div class="col-md-6 mt-4 mt-md-0">
                    <h3 class="label">Banco</h3>
                    <p class="text" v-empty="supplier.bank ? supplier.bank.name : null">
                    </p>
                  </div>
                </div>

                <div class="row mb-0">
                  <div class="col-md-5">
                    <h3 class="label">Número de cuenta bancaria</h3>
                    <p class="text" v-empty="supplier.bankAccount">
                    </p>
                  </div>

                  <div class="col-md-6">
                    <h3 class="label">CCI</h3>
                    <p class="text" v-empty="supplier.bankInterAccount">
                    </p>
                  </div>
                </div>
                <div class="row float-right mb-0">
                  <router-link :to="{ name: 'supplier-list' }" class="button button-light">
                    Volver
                  </router-link>
                </div>
              </div>
            </section>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Alert, Util} from 'wize-admin'
import {Constants as LocalConstants} from "@/core/utils";
import {SupplierService} from "@/core/services";

export default {
  data() {
    return {
      supplier: {}
    };
  },
  methods: {
    getManagementType(key) {
      return Util.searchValue(key, LocalConstants.ManagementType);
    },
    getType(key) {
      return Util.searchValue(key, LocalConstants.SupplierType);
    },
    async load(supplierId) {
      try {
        this.$store.dispatch("app/loading", true);
        const response = await SupplierService.get(supplierId);
        const payload = response.payload;
        this.supplier = {...payload};
      } catch (e) {
        console.error(e);
        Alert.error(e.errors.message);
        this.$router.push({name: "supplier-list"});
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
  },
  async created() {
    this.load(this.$route.params.supplierId);
  },
};
</script>

<style lang="stylus" scoped>
@import '../Styles/supplier-details';
</style>
