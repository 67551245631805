const Errors = {
  SERVER_ERROR: "Ocurrió un error en la petición, por favor intenta nuevamente.",
}

const PaymentStatus = {
  PENDING: {key: 1, label: 'PENDIENTE', color: 'badge-gray'},
  REVIEW: {key: 2, label: 'EN REVISIÓN', color: 'badge-yellow'},
  AUTHORIZED: {key: 3, label: 'AUTORIZADO', color: 'badge-yellow'},
  APPROVED: {key: 4, label: 'APROBADO', color: 'badge-green'},
  REJECTED: {key: 5, label: 'RECHAZADO', color: 'badge-red'},
  PAID: {key: 6, label: 'PAGADO', color: 'badge-purple'},
  INCORRECT: {key: 7, label: 'PAGO INCORRECTO', color: 'badge-red'}
}

const PushersStatus = {
  ACTIVE: {key: 1, label: 'ACTIVO', color: 'badge-green'},
  INACTIVE: {key: 2, label: 'SUSPENDIDO', color: 'badge-yellow'}
}

const ExpenseLineStatus = {
  ACTIVE: {key: 1, label: 'ACTIVO', color: 'badge-green'},
  INACTIVE: {key: 2, label: 'SUSPENDIDO', color: 'badge-yellow'}
}

const SupplierStatus = {
  ACTIVE: {key: 1, label: 'ACTIVO', color: 'badge-green'},
  INACTIVE: {key: 2, label: 'SUSPENDIDO', color: 'badge-yellow'},
  LOCKED: {key: 3, label: 'BLOQUEADO', color: 'badge-red'},
}

const VoucherType = {
  INVOICE: {key: 1, label: 'Factura'},
  CREDIT_NOTE: {key: 2, label: 'Nota de crédito'},
}

const RejectReasonStatus = {
  ACTIVE: {key: 1, label: 'ACTIVO', color: 'badge-green'},
  INACTIVE: {key: 2, label: 'SUSPENDIDO', color: 'badge-yellow'},
}

const ProjectTypeStatus = {
  ACTIVE: {key: 1, label: 'ACTIVO', color: 'badge-green'},
  INACTIVE: {key: 2, label: 'SUSPENDIDO', color: 'badge-yellow'},
}

const ZonalStatus = {
  ACTIVE: {key: 1, label: 'ACTIVO', color: 'badge-green'},
  INACTIVE: {key: 2, label: 'SUSPENDIDO', color: 'badge-yellow'},
}

const PaymentFrequency = {
  WEEKLY: {key: 1, label: 'SEMANAL', periods: 4},
  BIWEEKLY: {key: 2, label: 'QUINCENAL', periods: 2},
  MONTHLY: {key: 3, label: 'MENSUAL', periods: 1},
}

const ManagementType = {
  ON_THE_GROUP: {key: 1, label: 'EN EL GRUPO'},
  INDIVIDUAL: {key: 2, label: 'PROPIA'},
}

const SupplierType = {
  REGULAR: {key: 1, label: 'REGULAR'},
  SPECIAL: {key: 2, label: 'ESPECIAL'},
}

const BooleanTypes = {
  YES: {key: 1, label: 'SÍ'},
  NO: {key: 0, label: 'NO'},
}

const IdentifierType = {
  DNI: {key: 1, label: 'DNI'},
  CE: {key: 4, label: 'CARNE DE EXTRANJERIA'},
  RUC: {key: 6, label: 'RUC'},
  PASAPORTE: {key: 7, label: 'PASAPORTE'},
  CARNE_REFUGIO: {key: 7, label: 'CARNE DE SOLICIT DE REFUGIO'},
  CARNE_IDENTIDAD: {key: 22, label: 'CARNE DE IDENTIDAD – RR.EE.'},
  CARNE_PERMANENCIA: {key: 23, label: 'CARNE PERMISO TEMP.PERMANENCIA'},
  DNI_EXTRANJERO: {key: 24, label: 'DOC. DE IDENTIDAD EXTRANJERO'},
  OTROS: {key: 0, label: 'OTROS'},
}

const AccountType = {
  SAVINGS: {key: 1, label: 'AHORROS'},
  CURRENT: {key: 2, label: 'CORRIENTE'},
  SALARY: {key: 3, label: 'SUELDO'},
}

const PaymentBatchStatus = {
  PENDING: {key: 1, label: 'PENDIENTE', color: 'badge-gray'},
  APPROVED: {key: 2, label: 'APROBADO', color: 'badge-green'},
}

const VoucherTypes = {
  eInvoice: {key: 'eInvoice', label: 'Factura electrónica', value: 1},
  eReceipt: {key: 'eReceipt', label: 'Boleta electrónica',  value: 3},
  eReceiptForFees: {key: 'eReceiptForFees', label: 'Recibo por honorarios electrónicos', value: 4},
  manualInvoice: {key: 'manualInvoice', label: 'Factura manual', value: 5},
  manualReceipt: {key: 'manualReceipt', label: 'Boleta manual', value: 6},
}

export default {
  Errors,
  PaymentStatus,
  PushersStatus,
  SupplierStatus,
  ManagementType,
  RejectReasonStatus,
  BooleanTypes,
  ProjectTypeStatus,
  ZonalStatus,
  PaymentFrequency,
  IdentifierType,
  ExpenseLineStatus,
  SupplierType,
  VoucherType,
  AccountType,
  PaymentBatchStatus,
  VoucherTypes
}
