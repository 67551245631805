code

<template>
  <div class="page-container reject-reason-list">
    <div class="page-title-container">
      <h1 class="page-title">Motivos de rechazo</h1>
    </div>

    <div class="table-container">
      <div
        class="table-header d-flex flex-column flex-md-row justify-content-between align-items-start"
      >
        <div>
          <div
            class="d-flex align-items-center justify-content-between filter"
            @click="showFilters = !showFilters"
          >
            <FiltersIcon />

            <div>Filtrar motivos</div>
          </div>
        </div>

        <div class="d-flex flex-wrap align-items-center w-100 ml-2">
          <span
            v-for="(val, name) in filters"
            v-bind:key="name"
            class="d-flex justify-content-between mb-1 ml-2 filter-secondary"
          >
            {{ getFilterName(name, val) }}

            <DeleteFilterIcon @click="deleteFilter(name)" />
          </span>
        </div>

        <filter-menu
          :show="showFilters"
          @hide="showFilters = false"
          :options="filterOptions"
          :selectedOptionFilter="filters"
          @setFilters="setFilters"
        ></filter-menu>

        <section class="d-flex align-items-center">
          <SearchBar
            placeholder="Buscar por descripción"
            @search="onSearch"
            class="search-bar"
          />

          <b-button
            :to="{ name: 'config-reject-reason-create' }"
            variant="none"
            class="button button-primary button-table-action"
            >Agregar
          </b-button>
        </section>
      </div>
      <div class="mb-0">
        <table class="custom-table">
          <thead>
            <tr>
              <th>
                <div class="d-flex justify-content-center align-items-center">
                  <span class="d-inline-block pt-1">Descripción</span>
                </div>
              </th>
              <th>Anulación de comprobante</th>
              <th>Estado</th>
              <th class="pr-md-3">Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="o in list" :key="o.id">
              <td class="description">{{ o.description }}</td>
              <td class="cell-center">{{ o.cancellation ? "SI" : "NO" }}</td>
              <td class="cell-center pl-md-3">
                <span :class="`badge ${getStatus(o.status).color}`">{{
                  getStatus(o.status).label
                }}</span>
              </td>

              <td class="cell-center d_option pr-md-2 position-relative">
                <DropdownTable>
                  <template slot="dropdown-content">
                    <DropdownItem
                      text="Editar"
                      :to="{
                        name: 'config-reject-reason-edit',
                        params: { id: o.id },
                      }"
                    />
                    <DropdownItem
                      text="Activar"
                      @click="changeStatus(o.id, RejectReasonStatus.ACTIVE.key)"
                      v-if="o.status === RejectReasonStatus.INACTIVE.key"
                    />
                    <DropdownItem
                      text="Suspender"
                      @click="
                        changeStatus(o.id, RejectReasonStatus.INACTIVE.key)
                      "
                      v-if="o.status === RejectReasonStatus.ACTIVE.key"
                    />
                    <DropdownItem text="Eliminar" @click="deleteReason(o.id)" />
                  </template>
                </DropdownTable>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-footer d-flex justify-content-end align-items-center">
        <pagination
          :total="total"
          :page="page"
          :page-size="pageSize"
          :pages="pages"
          :changePage="changePage"
          :changePageSize="changePageSize"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Constants, Util } from 'wize-admin'
import { Constants as LocalConstants } from "@/core/utils";
import { RejectReasonService } from "@/core/services";

import {
  PaginationMixin,
  FilterMixin,
  Alert,
  FiltersIcon,
  DeleteFilterIcon,
  DropdownTable,
  DropdownItem,
  SearchBar,
  FilterMenu
} from "wize-admin";

export default {
  components: {
    SearchBar,
    DropdownTable,
    DropdownItem,
    FilterMenu,
    FiltersIcon,
    DeleteFilterIcon,
  },
  data() {
    return {
      list: [],
      tooltipType: null,
      RejectReasonStatus: LocalConstants.RejectReasonStatus,
      filterOptions: [
        {
          label: "Estado",
          key: "status",
          type: Constants.TypeFilters.SINGLE,
          options: [
            LocalConstants.RejectReasonStatus.ACTIVE,
            LocalConstants.RejectReasonStatus.INACTIVE,
          ],
        },
        {
          label: "Anulación del comprobante",
          key: "cancellation",
          type: Constants.TypeFilters.SINGLE,
          options: [
            {key: 1, label: 'SI'},
            {key: 2, label: 'NO'},
          ],
        },
      ],
    };
  },
  mixins: [FilterMixin, PaginationMixin],
  methods: {
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);
        const params = {
          page: this.page - 1,
          size: this.pageSize,
          sort: "description",
          search: this.search,
          ...this.filters,
        };
        const response = await RejectReasonService.list(params);
        this.list = response.payload.content;
        this.total = response.payload.totalElements;
        this.pages = response.payload.totalPages;
      } catch (e) {
        console.error(e);
        Alert.error(e.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    getStatus(key) {
      return Util.searchValue(key, LocalConstants.RejectReasonStatus);
    },
    changeStatus(id, status) {
      const self = this;
      Alert.dialog(
        "¿Desea cambiar el estado del motivo de rechazo?",
        null,
        async function () {
          try {
            await self.$store.dispatch("app/loading", true);
            const resp = (await RejectReasonService.changeStatus(id, status))
              .payload;

            const obj = self.list.find((o) => {
              return o.id === resp.id;
            });
            if (obj) {
              const index = self.list.indexOf(obj);
              self.$set(self.list, index, resp);
            }
            Alert.success("Estado actualizado correctamente");
          } catch (e) {
            console.error(e);
            Alert.error(e.errors.message);
          } finally {
            await self.$store.dispatch("app/loading", false);
          }
        }
      );
    },
    deleteReason(id) {
      const self = this;
      Alert.dialog(
        "¿Desea eliminar el motivo de rechazo?",
        null,
        async function () {
          try {
            await self.$store.dispatch("app/loading", true);
            const resp = (await RejectReasonService.deleteReason(id)).payload;

            const obj = self.list.find((o) => {
              return o.id === resp.id;
            });
            if (obj) {
              const index = self.list.indexOf(obj);
              self.list.splice(index, 1);
            }
            Alert.success("Motivo de rechazo eliminado correctamente");
          } catch (e) {
            console.error(e);
            Alert.error(e.errors.message);
          } finally {
            await self.$store.dispatch("app/loading", false);
          }
        }
      );
    },
  },
  async created() {
    await this.loadData(this.page, this.pageSize);
  },
};
</script>

<style lang="stylus" scoped>
@import '../Styles/reject-reason-list'
</style>
