<template>
  <div class="page-container reject-reason-editor">
    <div class="page-title-container">
      <h1 class="page-title">{{ editing ? "Editar motivo de rechazo" : "Nuevo motivo de rechazo" }}</h1>
    </div>
    <div class="container px-0">
      <div class="row justify-content-center">
        <div class="col-12">
          <b-card
              tag="article"
              no-body
              style="max-width: 640px"
              class="custom-card"
          >
            <b-card-header
                header-tag="section"
                class="custom-card-header card-header d-flex align-items-center"
            >
              <h3>{{ editing ? "Datos del motivo de rechazo" : "Datos del nuevo motivo de rechazo" }} </h3>
            </b-card-header>
            <b-card-body>
              <form
                  class="col-12 custom-form custom-form--pusher"
                  @submit.prevent="save"
                  novalidate
              >
                <section class="form-content">
                  <div class="form-group">
                    <label for="description">Descripción *</label>
                    <input
                        id="description"
                        type="text"
                        v-model.trim="form.description"
                        autocomplete="off"
                        placeholder="Ingresar descripción"
                        class="custom-form-control"
                        :class="{
                        'custom-form-control-error':
                          $v.form.description.$error
                      }"
                    />
                    <FormError
                        v-if="
                        $v.form.description.$error &&
                        !$v.form.description.required
                      "
                        message="Descripción es requerida"
                    />
                  </div>
                  <section class="form-group d-flex align-items-center">
                    <label for="cancellation" class="pt-2 mr-3 checkbox-label">Anulación completa del comprobante</label>
                    <b-form-checkbox
                      id="cancellation"
                      v-model="form.cancellation"
                      name="check-button"
                      switch
                      size="lg"
                    >
                    </b-form-checkbox>
                  </section>
                  <div class="form-group-buttons text-right">
                    <router-link
                        :to="{ name: 'config-reject-reason-list' }"
                        class="button button-light"
                    >
                      {{ editing ? 'Volver' : 'Cancelar' }}
                    </router-link>
                    <button class="button button-primary" type="submit">
                      Guardar
                    </button>
                  </div>
                </section>
              </form>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Alert, FormError } from 'wize-admin'

import {required} from "vuelidate/lib/validators";
import {RejectReasonService} from "@/core/services";

export default {
  components: {
    FormError
  },
  data() {
    return {
      form: {
        description: null,
        cancellation: false,
      },
      editing: false
    };
  },
  watch: {
    "$route.params.id": function () {
      if (this.$route.params.id) this.load(this.$route.params.id);
    },
  },
  methods: {
    async save() {
      const self = this;
      self.$v.$touch();
      if (self.$v.$error) return;

      try {
        this.$store.dispatch("app/loading", true);
        const data = {...this.form};
        let response;
        if (!this.editing) response = await RejectReasonService.save(data);
        else
          response = await RejectReasonService.update(
              this.$route.params.id,
              data
          );
        Alert.success("Se almacenó la información correctamente");
        if (!this.editing) {
          this.$router.push({
            name: "config-reject-reason-edit",
            params: {id: response.payload.id},
          });
          this.editing = true;
        }
      } catch (ex) {
        console.error(ex);
        Alert.error(ex.errors.message);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    async load(id) {
      try {
        this.$store.dispatch("app/loading", true);
        const response = await RejectReasonService.get(id);
        const payload = response.payload;
        this.form = {...payload};
        this.editing = true;
      } catch (e) {
        console.error(e);
        Alert.error(e.errors.message);
        this.$router.push({name: "config-reject-reason-list"});
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
  },
  validations: {
    form: {
      description: {required},
    },
  },
  mounted(){
    if (this.$route.params.id) this.load(this.$route.params.id);
  }
};
</script>

<style lang="stylus" scoped>
@import "../Styles/reject-reason-editor"
</style>
