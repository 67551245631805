<template>
  <div>
    <div :class="`wrapper-page${isMenuOpen ? '' : '-closeSidebar'}`">
      <!-- <page-sidebar /> -->
      <page-sidebar
        title="NAGA"
        :isMenuOpen="isMenuOpen"
        :tooltipType="tooltipType"
        @toggleMenu="toggleMenu"
      >
        <template #list-items>
          <router-link
            :to="{ name: 'dashboard' }"
            tag="li"
            v-if="$can('manage', 'dashboard')"
            exact-active-class="active"
            class="d-flex sidebar-item"
            @mouseover.native="tooltipType = 'home-item'"
            @mouseleave.native="tooltipType = null"
          >
            <div class="d-flex align-items-center">
              <HomeIcon />

              <span class="sidebar-item-text">Portada</span>

              <CustomTooltip
                custom-class="tooltip-sidebar"
                v-if="!isMenuOpen && tooltipType === 'home-item'"
                text="Portada"
              />
            </div>
          </router-link>

          <router-link
            :to="{ name: 'supplier-list' }"
            tag="li"
            v-if="$can('manage', 'supplier-list')"
            active-class="active"
            class="d-flex sidebar-item"
            @mouseover.native="tooltipType = 'supplier-item'"
            @mouseleave.native="tooltipType = null"
          >
            <div class="d-flex align-items-center">
              <!-- <div class="sidebar-item-icon supplier-icon"></div> -->

              <UserIcon class="sidebar-icon" />

              <span class="sidebar-item-text">Proveedores</span>
              <CustomTooltip
                custom-class="tooltip-sidebar"
                v-if="!isMenuOpen && tooltipType === 'supplier-item'"
                text="Proveedores"
              />
            </div>
          </router-link>

          <router-link
            event=""
            tag="li"
            active-class="active"
            v-if="$can('manage', 'config')"
            to="/config"
            class="sidebar-item d-flex align-items-center"
            @click.native.prevent="
              currentSubmenu === 'config'
                ? setCurrentSubmenu(null)
                : setCurrentSubmenu('config')
            "
          >
            <div
              class="d-flex flex-column justify-content-center sidebar-item-container"
            >
              <div
                class="d-flex align-items-center justify-content-between w-100"
              >
                <div
                  class="d-flex"
                  @mouseover="tooltipType = 'config-item'"
                  @mouseleave="tooltipType = null"
                >
                  <PencilIcon />
                  <span class="sidebar-item-text">Mantenimiento</span>
                  <CustomTooltip
                    custom-class="tooltip-sidebar"
                    v-if="!isMenuOpen && tooltipType === 'config-item'"
                    text="Mantenimiento"
                  />
                </div>
              </div>
              <span
                :class="`sidebar-item-description ${
                  isCurrent('config') ? 'active' : ''
                }`"
              >
                Impulsadores, Líneas de gasto, Motivos de rechazo
              </span>
            </div>
            <div v-if="isMenuOpen">
              <SidebarArrowTopIcon v-if="isCurrent('config')" />
              <SidebarArrowBottomIcon v-else />
            </div>
          </router-link>

          <section>
            <ul
              :class="`sidebar-submenu ${
                isCurrent('config') ? 'active' : ''
              } pl-0`"
            >
              <sub-item
                :isMenuOpen="isMenuOpen"
                routeName="config-pusher-list"
                text="Impulsadores"
                v-if="$can('manage', 'config-pusher-list')"
              ></sub-item>
              <sub-item
                :isMenuOpen="isMenuOpen"
                routeName="config-expense-line-list"
                text="Líneas de gasto"
                v-if="$can('manage', 'config-expense-line-list')"
              ></sub-item>
              <sub-item
                :isMenuOpen="isMenuOpen"
                routeName="config-reject-reason-list"
                text="Motivos de rechazo"
                v-if="$can('manage', 'config-reject-reason-list')"
              ></sub-item>
              <sub-item
                :isMenuOpen="isMenuOpen"
                routeName="config-project-type-list"
                text="Proyectos"
                v-if="$can('manage', 'config-project-type-list')"
              ></sub-item>
              <sub-item
                :isMenuOpen="isMenuOpen"
                routeName="config-zonal-list"
                text="Zonales"
                v-if="$can('manage', 'config-zonal-list')"
              ></sub-item>
            </ul>
          </section>

          <router-link
            event=""
            tag="li"
            active-class="active"
            to="/pagos"
            v-if="$can('manage', 'payments')"
            class="sidebar-item d-flex align-items-center"
            @click.native.prevent="
              currentSubmenu === 'payments'
                ? setCurrentSubmenu(null)
                : setCurrentSubmenu('payments')
            "
          >
            <div
              class="d-flex flex-column justify-content-center sidebar-item-container"
            >
              <div
                class="d-flex align-items-center justify-content-between w-100"
              >
                <div
                  class="d-flex"
                  @mouseover="tooltipType = 'payments-item'"
                  @mouseleave="tooltipType = null"
                >
                  <PaymentsIcon class="sidebar-icon" />

                  <span class="sidebar-item-text">Pagos y comisiones</span>
                  <CustomTooltip
                    custom-class="tooltip-sidebar"
                    v-if="!isMenuOpen && tooltipType === 'payments-item'"
                    text="Control diario"
                  />
                </div>
              </div>
              <span
                :class="`sidebar-item-description ${
                  isCurrent('payments') ? 'active' : ''
                }`"
              >
                Pagos y comisiones, Generar IDs de pago, Cargar abonos
              </span>
            </div>
            <div v-if="isMenuOpen">
              <SidebarArrowTopIcon v-if="isCurrent('payments')" />
              <SidebarArrowBottomIcon v-else />
            </div>
          </router-link>
          <section>
            <ul
              :class="`sidebar-submenu ${
                isCurrent('payments') ? 'active' : ''
              } pl-0`"
            >
              <sub-item
                :isMenuOpen="isMenuOpen"
                routeName="payments-list"
                text="Lista"
                v-if="$can('manage', 'payments-list')"
              ></sub-item>
              <sub-item
                :isMenuOpen="isMenuOpen"
                routeName="payments-batch-list"
                text="Generar IDs de pago"
                v-if="$can('manage', 'payments-batch-list')"
              ></sub-item>
              <sub-item
                :isMenuOpen="isMenuOpen"
                routeName="payments-made-batch-list"
                text="Cargar abonos"
                v-if="$can('manage', 'payments-made-batch-list')"
              ></sub-item>
            </ul>
          </section>

          <router-link
            :to="{ name: 'voucher-list' }"
            tag="li"
            exact-active-class="active"
            v-if="$can('manage', 'voucher-list')"
            class="d-flex sidebar-item"
            @mouseover.native="tooltipType = 'vouchers-item'"
            @mouseleave.native="tooltipType = null"
          >
            <div class="d-flex align-items-center">
              <VoucherIcon class="sidebar-icon" />
              <span class="sidebar-item-text">Comprobantes</span>
              <CustomTooltip
                custom-class="tooltip-sidebar"
                v-if="!isMenuOpen && tooltipType === 'vouchers-item'"
                text="Comprobantes"
              />
            </div>
          </router-link>
        </template>
      </page-sidebar>

      <div class="wrapper-page__content">
        <page-header />
        <div class="wrapper-page__inner">
          <b-container fluid>
            <slot></slot>
          </b-container>
        </div>
      </div>

      <page-footer />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import {
  PageSidebar,
  SubItem,
  CustomTooltip,
  HomeIcon,
  UserIcon,
  PencilIcon,
  SidebarArrowBottomIcon,
  SidebarArrowTopIcon,
  PaymentsIcon,
  VoucherIcon,
  PageFooter,
} from "wize-admin";

import PageHeader from "./PageHeader";

export default {
  name: "PageBase",
  components: {
    PageHeader,
    PageSidebar,
    PageFooter,
    CustomTooltip,
    SubItem,
    HomeIcon,
    UserIcon,
    PencilIcon,
    SidebarArrowBottomIcon,
    SidebarArrowTopIcon,
    PaymentsIcon,
    VoucherIcon,
  },
  data() {
    return {
      matchMediaMd: window.matchMedia("(max-width: 768px)"),
      tooltipType: null,
    };
  },
  computed: {
    ...mapGetters({
      currentSubmenu: "app/getCurrentSubmenu",
      isMenuOpen: "app/isMenuOpen",
    }),
  },
  methods: {
    ...mapMutations({
      setCurrentSubmenu: "app/setCurrentSubmenu",
      setMenu: "app/setMenu",
      toggleMenu: "app/toggleMenu",
    }),
    isMatchMedia() {
      if (this.matchMediaMd.matches) {
        this.setMenu(false);
      } else {
        this.setMenu(true);
      }
    },
    isCurrent(submenu) {
      return this.currentSubmenu.includes(submenu);
    },
  },
  created() {
    this.matchMediaMd.addListener(this.isMatchMedia);
  },
};
</script>

<style scoped lang="stylus">
</style>
